<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{ path: '/raceManagement/camp/campListData' }"
                >营地管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>营地成员</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>成员筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="80px"
                ref="queryFormRef"
            >
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="姓名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="userPhone">
                            <el-input
                                v-model="queryForm.userPhone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否缴费" prop="userName">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.payd"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="入营测试" prop="trial">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.trial"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="签署协议" prop="contract">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.contract"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        size="small"
                        style="width: 100%"
                        @click="getCampList"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="warning"
                        icon="el-icon-refresh-right"
                        size="small"
                        style="width: 100%"
                        @click="queryReset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span>成员列表</span>
                </el-col>
                <el-col :span="12">
                    <div style="text-align: right">
                        <el-button
                            type="success"
                            size="small"
                            @click="addInfoDialog = true"
                            icon="el-icon-circle-plus-outline"
                            >新增成员</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            @click="campUserExport"
                            icon="el-icon-download"
                            >导出成员</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                :data="campUserList"
                header-cell-class-name="tableHeaderStyle"
                border
                stripe
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="姓名" prop="userName">
                </el-table-column>
                <el-table-column
                    label="手机号"
                    prop="userPhone"
                ></el-table-column>
                <el-table-column label="是否缴费" prop="payd">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.payd === true"
                            size="mini"
                            >已缴费</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">未缴费</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="缴费金额" prop="orderAmount">
                    <template slot-scope="scope">
                        <span style="color: red" v-if="scope.row.orderAmount"
                            >￥{{ scope.row.orderAmount }}</span
                        >
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="订单号"
                    prop="orderNo"
                ></el-table-column>
                <el-table-column label="是否入营测试" prop="trial">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.trial === true"
                            size="mini"
                            >已测试</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">未测试</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否签合同(协议)" prop="contract">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.contract === true"
                            size="mini"
                            >已签署</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">未签署</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="note"></el-table-column>
                <el-table-column label="操作" width="120px">
                    <template scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="editCampDetail(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="small"
                            @click="showDeleteCampTip(scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                v-if="campUserList.length > 0"
            >
            </el-pagination>
        </el-card>
        <el-dialog
            title="添加营员信息"
            :visible.sync="addInfoDialog"
            width="50%"
            @close="handleCloseDialog"
        >
            <el-form
                :model="addInfoModel"
                label-width="100px"
                ref="addInfoFormRef"
                :rules="addInfoRules"
                style="width: 100%"
            >
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="营员姓名" prop="userName">
                            <el-input
                                v-model="addInfoModel.userName"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="userPhone">
                            <el-input
                                v-model="addInfoModel.userPhone"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="所属赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="addInfoModel.districtId"
                                @change="districChange($event, dict_district)"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="addInfoModel.schoolId"
                                @change="schoolChange($event, provincesSchoolList)"
                                clearable
                            >
                                <el-option
                                    v-for="item in provincesSchoolList"
                                    :key="item.id"
                                    :label="item.schoolName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="是否入营测试" prop="trial">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="addInfoModel.trial"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="测试时间" prop="trialTime">
                            <el-date-picker
                                style="width: 100%"
                                v-model="addInfoModel.trialTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="是否缴费" prop="payd">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="addInfoModel.payd"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="缴费时间" prop="paydTime">
                            <el-date-picker
                                style="width: 100%"
                                v-model="addInfoModel.paydTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="是否签署协议" prop="contract">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="addInfoModel.contract"
                            >
                                <el-option
                                    v-for="item in boolData"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="签署时间" prop="contractTime">
                            <el-date-picker
                                style="width: 100%"
                                v-model="addInfoModel.contractTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="订单号" prop="orderNo">
                            <el-input
                                v-model="addInfoModel.orderNo"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注" prop="note">
                            <el-input
                                v-model="addInfoModel.note"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="缴费凭证" prop="paymentVoucherUrl">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadImageResults.uploadUrl"
                                :show-file-list="false"
                                :on-success="uploadCoverImgSuccess"
                                :headers="uploadImageResults.headerObj"
                                :data="{
                                    filePath:
                                        'spbcnapp/camp/paymentVoucherImg/',
                                }"
                            >
                                <img
                                    v-if="addInfoModel.paymentVoucherUrl"
                                    class="img-cover"
                                    :src="addInfoModel.paymentVoucherUrl"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addCampersClick"
                    >保 存</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getAllSchoolList, campUserPage, campUserDel, campUserInsert, campUserEdit, queryDistrictPage } from '@/http/api'

export default {
  data () {
    return {
      uploadImageResults: {
        uploadUrl: this.$http.defaults.baseURL + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      dict_district: [],
      addInfoDialog: false,
      addInfoModel: {
        campId: this.$route.query.campId,
        userPhone: '',
        schoolId: null,
        userName: '',
        districtId: null,
        trial: null,
        trialTime: '',
        payd: null,
        paydTime: '',
        orderNo: '',
        note: '',
        contractTime: '',
        contract: ''
      },
      addInfoRules: {
        userName: [{ required: true, message: '请输入', trigger: 'blur' }],
        userPhone: [{ required: true, message: '请输入', trigger: 'blur' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }],
        districtId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      boolData: this.$userInfo.dict_boolData(),
      queryForm: {
        campId: this.$route.query.campId,
        pageNum: 1,
        pageSize: 10,
        userName: '',
        userPhone: ''
      },
      total: 0,
      provincesSchoolList: [],
      campUserList: []
    }
  },
  created () {
    this.getDataRequest()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getDataRequest () {
      const promises = [
        this.getAllSchoolList(),
        this.getDistrictList(),
        this.getCampList()
      ]
      Promise.all(promises)
        .then(() => {
        // 执行后续操作
        })
        .catch((error) => {
          console.error('有一个请求失败了', error)
          this.$confirm('基础数据请求失败, 是否刷新?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.getDataRequest()
          }).catch(() => {
          })
        })
    },
    // 获取全部学校list
    getAllSchoolList () {
      getAllSchoolList({}).then((res) => {
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getCampList () {
      console.log('queryForm', this.queryForm)
      campUserPage(this.queryForm).then((res) => {
        this.campUserList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getCampList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getCampList()
    },
    queryReset () {
      this.$refs.queryFormRef.resetFields()
      this.getCampList()
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 营员点击事件
    addCampersClick () {
      if (this.addInfoModel.id) {
        campUserEdit(this.addInfoModel).then((res) => {
          this.$message.success('保存成功')
          this.$refs.addInfoFormRef.resetFields()
          this.addInfoDialog = false
          this.getCampList()
        }).catch((err) => {
          console.log('err', err)
        })
      } else {
        campUserInsert(this.addInfoModel).then((res) => {
          this.$message.success('添加成功')
          this.$refs.addInfoFormRef.resetFields()
          this.addInfoDialog = false
          this.getCampList()
        }).catch((err) => {
          console.log('err', err)
        })
      }
    },
    // 编辑成员信息
    editCampDetail (rowData) {
      this.addInfoModel = JSON.parse(JSON.stringify(rowData))
      this.addInfoDialog = true
    },
    uploadCoverImgSuccess (res) {
      console.log('res', res)
      this.$set(this.addInfoModel, 'paymentVoucherUrl', res.data)
    },
    // 导出营员
    campUserExport () {
      var downLoadUrl = this.$http.defaults.baseURL + 'campUser/export'
      var formData = JSON.parse(JSON.stringify(this.queryForm))
      formData.pageSize = 1000
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', formData)
    },
    handleCloseDialog () {
    },
    showDeleteCampTip (id) {
      this.$confirm('此操作将删除该营地成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        campUserDel(id).then((res) => {
          this.$message.success('删除成功')
          this.getCampList()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => { })
    },
    districChange (selectedId, districtList) {
      const selectedOption = districtList.find(item => item.id === selectedId)
      if (selectedOption) {
        // 可以在这里进行其他操作，比如将 id 和 districtName 存储到其他变量或对象中
        this.addInfoModel.districtName = selectedOption.districtName
      }
    },
    schoolChange (selectedId, allSchoolList) {
      const selectedOption = allSchoolList.find(item => item.id === selectedId)
      if (selectedOption) {
        // 可以在这里进行其他操作，比如将 id 和 districtName 存储到其他变量或对象中
        this.addInfoModel.schoolName = selectedOption.schoolName
      }
    }
  }
}
</script>

<style lang="less" scoped>
.button-right {
    margin-left: 20px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.img-cover {
    width: 120px;
    display: block;
}
</style>
